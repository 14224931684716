@import url("https://fonts.googleapis.com/css2?family=Hubballi&family=Lato:wght@300&family=League+Spartan:wght@200&family=Roboto:wght@100&family=Source+Sans+Pro:wght@300&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200&family=Prompt:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=DM+Serif+Display:ital@0;1&family=Fredericka+the+Great&family=Tilt+Neon&display=swap");

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  height: 100%;
  width: 100%;
  padding: 0;
  font-family: "source sans pro", "monospace", sans-serif;
  box-sizing: border-box;
}

.nav-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5rem;
  width: 100%;
  background: transparent;
  z-index: 10;
  display: flex;
}

.nav-items {
  display: flex;
  align-items: center;
  list-style: none;
}

.nav-item {
  margin-left: 20px;
  font-family: "Montserrat", serif;
  font-style: bold;
  text-decoration: none;
  margin-top: 20px;
  font-size: 1.5rem;
}

#dots-menu {
  cursor: pointer;
  position: absolute;
  right: 20px;
  font-size: 2.9rem;
  color: #2a9d8f;
}
#dots-menu:hover {
  cursor: pointer;
  position: absolute;
  right: 20px;
  font-size: 2.9rem;
  color: white;
}

.connect-container {
  position: absolute;
  left: 2rem;
  top: 1.9rem;
  display: flex;
  align-items: center;
}

.github a {
  font-size: 1.5rem;
  margin-right: 1rem;
  color: white;
  cursor: pointer;
}

.linkedin a {
  font-size: 1.5rem;
  margin-right: 1rem;
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.linkedin a:hover {
  color: grey;
}

.github a:hover {
  color: grey;
}

.list-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 100px;
}

@media (max-width: 600px) {
  .nav-items {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    font-size: 12px;
  }
}

.link {
  color: #00afb5;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.1s;
}

.link:hover {
  color: #e76f51;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.active {
  text-decoration: underline;
  text-underline-offset: 3px;
}

.home-link {
  font-size: 30px;
  cursor: pointer;
  color: #00afb5;
  transition: all 0.1s;
  margin-left: 20px;
}

.home-link:hover {
  color: #e76f51;
}

/* ------------HOME---------- */
/* ------------------------------HOME---------- */
/* ------------------------------HOME---------- */
/* ------------HOME---------- */
/* ------------HOME---------- */

.bg-wrap-light {
  position: relative;
}

.bg-wrap-light::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  /* background-image: url("./assets/jpg/bg5.jpg");
  background-repeat: no-repeat; */
  /* #7fc7d5 top #7ccad9 #aacbf2 bottom*/

  background: linear-gradient(
    #82c3c5,
    #7fc7d5,
    #7ccad9,
    #aacbf2,
    #aacbf2,
    #7ccad9,
    #7bc3c7
  );
  background-size: 150% 150%;
  animation: gradient 200s ease infinite;
}

.home-light {
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.title-1 {
  background-color: white;
  width: auto;
  height: auto;
}

.header-light {
  color: black;
  font-size: 5rem;
  animation: fadeIn 10s;
  margin-top: 300px;
  margin-left: 50px;
  font-family: "DM Serif Display", serif;
}

.lumi-light {
  font-family: "DM Serif Display", serif;
  z-index: 100;
  position: relative;
}

.title-2 {
  margin-top: 50px;
  width: auto;
  height: auto;
}

.wordchange-light::before {
  font-family: "montserrat";

  margin-left: 50px;
  color: black;
  font-size: 3rem;
  content: "";
  animation: wordchange 20s infinite ease;
}

.dot-light {
  position: absolute;
  top: 17%;
  left: 87%;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  z-index: 5;
  background-color: #051427;
  opacity: 100%;
  border: 0.05rem solid rgb(255, 240, 240);
  box-shadow: inset 0 0 90px rgba(251, 172, 19, 0.4), 0 0 50px 10px#f8bc04,
    0 0 120px 20px rgba(255, 255, 255, 0.6),
    0 0 20px 10px rgba(255, 255, 255, 0.9);
}

.title-3 {
  width: auto;
  height: auto;
}
.home_p-light {
  font-family: "montserrat";
  position: relative;
  color: #000000;
  font-size: 1.2rem;
  margin-left: 50px;
  line-height: 2;
  margin-bottom: 50px;
}
.nebula-light {
  visibility: hidden;
  width: 0px;
  height: 0px;
}

.laptop-light {
  position: absolute;
  top: 1%;
  left: 1%;
  opacity: 0.7;
  width: 100%;
  height: 500px;
  overflow: hidden;
  background-blend-mode: color-burn;
}

.rectangle-container {
  width: 100%;
}
.rectangle-light {
  width: 100%;
  overflow: hidden;
  margin-top: -5px;
  color: #7bc3c7;
}

@media (max-width: 500px) {
  .header-light {
    margin-top: 100px;
    margin-left: 20px;
    font-size: 4rem;
  }
  .dot-light {
    position: absolute;
    top: 15%;
    left: 87%;
    width: 1.2rem;
    height: 1.2rem;
  }
  .header-dark {
    margin-top: 100px;
    margin-left: 20px;
  }
  .title-2 {
    padding-top: 30px;
  }

  .laptop-light {
    position: absolute;
    top: 5%;
    left: 0%;
    opacity: 0.9;
    width: 100%;
    height: 200px;
    overflow: hidden;
    background-blend-mode: color-burn;
  }
}

@media (max-width: 768px) {
  .wordchange-light::before {
    font-family: "montserrat", serif;
    font-size: 1.3rem;
    margin: 20px;
  }
  .home_p-light {
    font-size: 0.8rem;
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (min-width: 800px) {
  .laptop-light {
    position: absolute;
    top: -9%;
    left: 10%;
    opacity: 0.6;
    width: 90%;
    overflow: hidden;
    height: 600px;

    background-blend-mode: color-burn;
  }
}

.heading_2 {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 800px;
  margin-bottom: 100px;
  color: #001524;
  font-size: 50px;
}

.arrow {
  width: 15rem;
  height: 5rem;
  background-color: #001524;
  z-index: 100;
  color: #15616d;
  padding: 10px 10px;
  font-size: 5rem;
  cursor: pointer;
  border-radius: 2px 2px;
  background: transparent;
  animation: fadeIn 10s;
}

.arrow:hover,
.arrow:focus {
  transform: translateY(-0.1em);
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.arrow_style-light {
  color: #001524;
}

.btn-home {
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0;
  color: #fff;
  cursor: pointer;
  background: transparent;
  font-size: 16px;
  font-weight: 400;
  line-height: 45px;
  margin: 10px auto;
  font-family: "montserrat", serif;
  margin-left: 50px;
  max-width: 200px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  border-bottom: 2px solid #4a4e69;
  border-top: 2px solid #4a4e69;
  border-left: none;
  border-right: none;
  border-radius: 5px;
  width: 100%;
  transform-origin: bottom;
  transition: ease-in 0.09s;
}

.btn-home:hover,
.btn-home:active {
  background-color: #4a4e69;
  letter-spacing: 1px;
  box-shadow: 4px 5px #001524;
}

@media (max-width: 500px) {
  .btn-home {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    width: 100px;
    font-size: 10px;
    height: 30px;
  }
}

/* -----Dark bg universe ------- */
/* -----Dark bg universe ------- */
/* -----Dark bg universe ------- */

.universe-dark {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.universe-light {
  visibility: hidden;
}

.star-dark {
  position: absolute;
  opacity: 0.5;
}

.star-light {
  visibility: hidden;
}

.star-dark:nth-child(2) {
  top: 57%;
  left: 55%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.8s;
}

.star-dark:nth-child(3) {
  top: 17%;
  left: 5%;
  animation: starFlicker;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}

.star-dark:nth-child(4) {
  top: 10%;
  left: 39%;
  animation: starFlicker;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.8s;
}

.star-dark:nth-child(5) {
  top: 90%;
  left: 27%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
}

.star-dark:nth-child(6) {
  top: 9%;
  left: 67%;
  animation: starFlicker;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.8s;
}

.star-dark:nth-child(7) {
  top: 12%;
  left: 55%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}

.star-dark:nth-child(8) {
  top: 60%;
  left: 90%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.6s;
}

.star-dark:nth-child(9) {
  top: 30%;
  left: 89%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}

.star-dark:nth-child(10) {
  top: 76%;
  left: 27%;
  animation: starFlicker;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.9s;
}

.star-dark:nth-child(11) {
  top: 9%;
  left: 12%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.4s;
}

.star-dark:nth-child(12) {
  top: 98%;
  left: 22%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}

.star-dark:nth-child(13) {
  top: 50%;
  left: 43%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}

.star-dark:nth-child(14) {
  top: 47%;
  left: 25%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1.8s;
}
.star-dark:nth-child(15) {
  top: 17%;
  left: 25%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 8s;
}
.star-dark:nth-child(16) {
  top: 77%;
  left: 75%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 4s;
}
.star-dark:nth-child(17) {
  top: 7%;
  left: 55%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 8s;
}
.star-dark:nth-child(18) {
  top: 29%;
  left: 69%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 8s;
}
.star-dark:nth-child(19) {
  top: 39%;
  left: 35%;
  animation: starFlicker;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 8s;
}

.shooting_star-dark {
  position: absolute;
  right: 90%;
  top: -15%;
  width: 1px;
  height: 70px;
  background: #fff;
  transform: rotate(-25deg);
  animation: shootingStar;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  animation-delay: 1s;
  overflow: hidden;
}
.shooting_star-light {
  visibility: hidden;
  animation: none;
}

.satellite-dark {
  position: absolute;
  top: 10%;
  left: 0;
  animation-name: orbit;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-delay: 10s;
}

.satellite-light {
  visibility: hidden;
  animation: none;
}

:root {
  --ff-primary: "source sans pro";
}

/* ----about----- */
/* ----about----- */
/* ----about----- */

.img-me {
  margin-top: 20px;
  margin-bottom: 35px;
}

.about-container-light {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  flex-direction: column;
  margin-top: -150px;
}

.about-description-light {
  width: 90%;
  padding: 20px;
  margin-top: 200px;
  margin-bottom: 50px;
  overflow: hidden;
}

.about-description-light p {
  font-family: "montserrat";
  font-size: 1.5rem;
  margin-bottom: 1rem;
  margin: 20px;
  color: #000000;
}

.about-description-light h3 {
  font-size: 1.9rem;
  margin: 20px;
  letter-spacing: 0.15em;
  color: #000000;
}

.about-h1 {
  margin-top: 150px;
}

.sub-container-light {
  display: flex;
  justify-content: flex-start;

  padding: 30px;
  height: 600px;
  box-shadow: 0 0 20px rgba(95, 95, 95, 0.37);
}
.sub-container-dark {
  display: flex;
  justify-content: flex-start;

  padding: 30px;
  height: 600px;
  box-shadow: 0 0 20px rgba(95, 95, 95, 0.37);
  border-radius: 20px;
}

/* services ------- */
/* services ------- */
/* services ------- */
/* services ------- */

.services-container {
  font-family: "montserrat", serif;

  width: 100%;
  list-style-type: none;
  margin-top: 50px;
  margin-bottom: 100px;
}

.services-row {
  display: flex;
  justify-content: center;
  align-self: center;

  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.services-row div {
  width: 500px;
  height: fit-content;
  list-style-type: none;
  text-align: center;
  margin: 10px;
}

.services-item-light {
  flex-grow: 1;
  margin: 10px;
}
.services-item-light h3 {
  font-family: "DM Serif Display", serif;
  font-size: 1.9rem;
  color: #000000;
}
.services-item-light p {
  font-family: "montserrat", serif;
  line-height: 1.5;
  font-weight: 200;
  font-size: 1.3rem;
  color: #000000;
}

/* ----projects----- */
/* ----projects----- */
/* ----projects----- */

.projects-container-light {
  padding-top: 3rem;
  background-color: white;
}

.sub-header {
  display: block;
  width: 100%;
  height: 30px;
  margin: 4rem auto;
  padding-left: 2rem;
}

.sub-header h1 {
  font-size: 35px;
  color: #78a1bb;
}

.sub-header_underline {
  width: 75px;
  height: 2px;
  margin-top: 10px;
  background-color: #fefefe;
}

.sub-header_underline_contact {
  width: 75px;
  height: 2px;
  margin-top: 10px;
  background-color: #e76f51;
}

@media screen and (max-width: 640px) {
  .sub-header {
    display: block;
    width: 100%;
    font-size: 40px;
    color: #78a1bb;
    height: 30px;
    margin: 5rem auto;
    padding-left: 1rem;
  }
  .services-row div {
    width: auto;
    padding: 10px;
    height: fit-content;
    list-style-type: none;
    text-align: center;
  }
}

@media (min-width: 1024px) {
  #home,
  #about,
  #projects,
  #contact {
    margin: 0;
  }
  .img-me {
    margin-left: 50px;
  }
}

.sub-container-about {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

/* @media (min-width: 200px) {
  .about-description {
    width: 80%;
  }
} */

@media (max-width: 650px) {
  .sub-container-about {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .about-container-light {
    padding-top: 100px;
  }
  .about-container-dark {
    padding-top: 100px;
  }

  .about-description-light {
    padding: 20px;
    margin-top: -10px;
    line-height: 1.5;
    font-size: 1rem;
  }
  .about-description-light p {
    font-size: 1.2rem;
  }
  .about-description-light h3 {
    font-size: 1.5rem;
  }
}

.skills-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  width: 100%;
  height: 200px;
  margin-bottom: 50px;
}

.skills-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5%;
  color: #e9e9e9;
}
@media (min-width: 800px) {
  .about-description-light {
    padding: 20px;

    line-height: 1.5;
  }
  .about-description-dark {
    padding: 20px;
    margin-top: 10px;
    line-height: 1.5;
  }
  .sub-container-light {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 60rem;
    margin-bottom: 0px;
    margin-top: 10px;
  }
  .sub-container-dark {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 60rem;
    margin-bottom: 0px;
    margin-top: 10px;
  }
}

.about-container-light {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  flex-direction: column;
  margin-top: -150px;
}
.about-container-dark {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(42 42 50);
  flex-direction: column;
  margin-top: -150px;
}

.img-me {
  display: flex;
  align-self: center;
  justify-content: center;
  margin-top: 150px;

  width: 150px;
  height: 150px;
}

#img {
  width: 100%;
  border-radius: 100px;
}

.projects-container-dark {
  width: 100%;
  background-color: rgb(41 39 42);
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 13rem;
  font-size: 1.2rem;
  line-height: 1.5;
}

.item:nth-child(4) {
  padding-bottom: 10rem;
  margin-bottom: 0px;
}

.item .left {
  flex: 1;
}

.item .left .project-image {
  cursor: pointer;
  height: 500px;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0px 0px 20px rgba(3, 3, 3, 0.637);
  position: relative;
}

.item .left .project-image::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(35, 63, 80, 0.296);
}

.item .left .project-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  right: 20px;
  transition: 0.6s ease transform;
  filter: grayscale(50%);
}

.item:hover .left .project-image img {
  transform: scale(1.09);
  z-index: 1;
  transition: 0.4s ease-in transform;
  filter: grayscale(0) brightness(110%);
}

.item .right {
  flex: 1;
  margin-left: -15px;
  opacity: 10;
  font-weight: 800;
  padding: 4rem 2rem;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(95, 95, 95, 0.37);
  z-index: 2;
}

@media only screen and (max-width: 768px) {
  .item .right {
    padding: 3rem 1.6rem;
  }
}

.item.reverse .right {
  margin-left: 0;
}

.item.reverse .left {
  margin-left: -25px;
}

.item.reverse {
  flex-direction: row-reverse;
}

@media only screen and (max-width: 768px) {
  .item {
    flex-direction: column;
  }

  .item .left {
    width: 98%;
  }

  .item .right {
    width: 98%;
    margin-left: 0px;
    margin-top: -50px;
  }

  .item.reverse {
    flex-direction: column;
  }

  .item.reverse .right {
    width: 98%;
    margin-left: 0px;
    margin-top: -50px;
  }

  .item.reverse .left {
    margin-left: 2px;
    width: 98%;
  }
}

p,
h1 {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #fff;
}

.btn {
  letter-spacing: 0;
  color: #fff;
  cursor: pointer;
  background: transparent;
  font-size: 16px;
  font-weight: 400;
  line-height: 45px;
  margin: 10px auto;

  margin-right: 30px;
  max-width: 120px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  border-bottom: 2px solid #4a4e69;
  border-top: 2px solid #4a4e69;
  border-left: none;
  border-right: none;
  border-radius: 5px;
  width: 100%;
  transform-origin: bottom;
  transition: ease-in 0.09s;
}

.btn:hover,
.btn:active {
  background-color: #4a4e69;
  letter-spacing: 1px;
  box-shadow: 4px 5px #001524;
}

.project-icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 0;
}

.skills-row {
  display: flex;
  justify-content: space-around;
}

.contacts-container {
  height: 100%;
  width: 100%;
  font-size: larger;
  margin-bottom: 10rem;
}

@media screen and (max-width: 768px) {
  .form-info {
    display: flex;
    justify-content: center;
    width: 300px;
  }

  .submit-btn {
    width: 200px;
  }
}

#contact-form {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.form-container {
  display: flex;
  align-items: center;
  height: 20rem;
  flex-direction: column;
  width: 70%;
  margin-bottom: 100px;
}

.form-container h1,
h2 {
  font-family: Montserrat;
  color: #161a1d;
  font-size: 1.2rem;
  margin: 20px auto;
  text-align: center;
}

.form-info input {
  font-family: Montserrat;
  margin: 2px;
  font-size: 20px;
  background-color: #4a4e69;
  width: 600px;
  outline: none;
  border: solid 1px black;
  color: #fefefe;
  padding: 5px;
}

.form-control-message {
  font-family: Montserrat;
  font-size: 20px;
  height: 200px;
  width: 600px;
  background-color: #4a4e69;
  border: solid 1px black;
  color: #fefefe;
  outline: none;
  margin: 2px;
  padding: 5px;
}

.submit-btn {
  padding: 10px;
  margin: 10px auto;
  background-color: transparent;
  border: 1px solid #161a1d;
  font-family: Montserrat;
  cursor: pointer;
  flex: 1;
  font-size: 15px;
  letter-spacing: 2px;
  width: 150px;
}

.submit-btn:disabled {
  padding: 10px;
  margin: 10px auto;
  border: 1px solid #161a1d;
  cursor: pointer;
  flex: 1;
  font-size: 15px;
  letter-spacing: 2px;
  background-color: #15616d;
  color: #4fd9de;
  width: 150px;
}

.submit-btn:disabled:hover {
  transform: scale(1);
  background-color: #15616d;
  color: #4fd9de;
}

.submit-btn:hover {
  transform: scale(1.1);
  transition: ease-in-out 0.6s transform;
  background-color: #00afb5;
}

.email-sent {
  height: 500px;
  width: 500px;
  font-size: 50px;
  color: black;
  z-index: 1000;
}

.footer-container {
  align-items: center;
  justify-content: center;
  letter-spacing: 3px;
  background-color: #003554;
  margin-bottom: 0;
  color: #001524;
  height: 4rem;
  width: 100%;
  z-index: 10;
  display: flex;
}

@media (max-width: 600px) {
  .footer-container p {
    font-size: 10px;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(-600px);
    width: 0rem;
    opacity: 20px;
  }

  50% {
    width: 0rem;
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(600px);
    width: 0rem;
  }

  50% {
    width: 5rem;
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes shootingStar {
  0% {
    top: -13%;
    right: 95%;
  }

  2% {
    opacity: 0;
    top: 100%;
    right: 0;
  }

  86% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes starFlicker {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes orbit {
  0% {
    top: 50%;
    left: 0;
    transform: rotate(0);
  }

  100% {
    top: 70%;
    left: 100%;
    transform: rotate(360deg);
  }
}

@keyframes wordchange {
  0% {
    content: "Responsive Design";
    opacity: 0;
  }
  10% {
    content: "Responsive Design";
    opacity: 1;
  }
  19% {
    content: "Responsive Design";
    opacity: 0;
  }
  20% {
    content: "Search Optimisation";
    opacity: 0;
  }
  30% {
    content: "Search Optimisation";
    opacity: 1;
  }
  39% {
    content: "Search Optimisation";
    opacity: 0;
  }
  40% {
    content: "Web Design and Development";
    opacity: 0;
  }
  50% {
    content: "Web Design and Development";
    opacity: 1;
  }
  59% {
    content: "Web Design and Development";
    opacity: 0;
  }
  60% {
    content: "Responsive Design";
    opacity: 0;
  }
  70% {
    content: "Responsive Design";
    opacity: 1;
  }
  79% {
    content: "Responsive Design";
    opacity: 0;
  }
  80% {
    content: "Search Optimisation";
    opacity: 0;
  }
  90% {
    content: "Search Optimisation ";
    opacity: 0.5;
  }
  100% {
    content: "Search Optimisation ";
    opacity: 0;
  }
}
