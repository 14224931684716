@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=DM+Serif+Display:ital@0;1&family=Fredericka+the+Great&family=Tilt+Neon&display=swap");

.top-dark-mode-button {
  position: absolute;
  top: 22px;
  right: 80px;
  z-index: 1000;
  transition: 0.3s ease-in;
}
.dark {
  height: 50px;
  width: 50px;
  border: none;
  background-color: transparent;
  background-image: url("./assets/svg/sun.svg");
  background-repeat: no-repeat;
  padding: 1px;
  cursor: pointer;
}
.light {
  height: 50px;
  width: 50px;
  padding: 1px;
  background-color: transparent;
  border: none;
  background-image: url("./assets/svg/moon.svg");
  background-repeat: no-repeat;
  cursor: pointer;
}
/*----- HOME -------*/
.bg-wrap-dark {
  position: relative;
}

.bg-wrap-dark::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  /* background-image: url("./assets/jpg/bg5.jpg");
    background-repeat: no-repeat; */
  background: linear-gradient(black, black);
  background-size: 150% 150%;
  animation: gradient 200s ease infinite;
}

.home-dark {
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.nebula-dark {
  position: absolute;
  top: -8%;
  left: 40%;
  background-blend-mode: multiply;
  opacity: 0.6;
  border-radius: 50%;
  background: transparent;
}

.laptop-dark {
  visibility: hidden;
  height: 0;
  width: 0;
}

.header-dark {
  color: white;
  font-size: 5rem;
  animation: fadeIn 10s;
  margin-top: 300px;
  margin-left: 50px;
  font-family: "DM Serif Display", serif;
}

.lumi-dark {
  font-family: "DM Serif Display", serif;
  background-color: transparent;
  filter: drop-shadow(-10px -5px 30px #ff7bac);
  text-shadow: 0 0 10px #ff7bac;
  /* ,
  , 0 0 80px #ff7bac, 0 0 100px , 0 0 120px #ff7bac; */
  animation: blink 6s alternate both ease-in-out;
}

.wordchange-dark::before {
  font-family: "montserrat", serif;

  margin-left: 50px;
  color: white;
  font-size: 3rem;
  content: "";
  animation: wordchange 20s infinite ease;
  text-shadow: 0 0 5px #013026, 0 0 10px #014760, 0 0 20px #107e57,
    0 0 5px #a1ce3f;
}

.home_p-dark {
  font-family: "montserrat", serif;
  position: relative;
  color: white;
  font-size: 1.2rem;
  margin-left: 50px;
  line-height: 2;
  margin-bottom: 50px;
}

.rectangle-dark {
  width: 100%;
  overflow: hidden;
  margin: 0;
  color: black;
}

@media (max-width: 500px) {
  .home-dark {
    height: fit-content;
  }
  .dot-dark {
    position: absolute;
    top: 15%;
    left: 87%;
    width: 1.2rem;
    height: 1.2rem;
  }
  .header-dark {
    margin-top: 100px;
    margin-left: 20px;
    font-size: 4rem;
  }
  .title-2 {
    margin-top: 10px;
  }

  .nebula-dark {
    position: absolute;
    top: 2%;
    left: -15%;
    background-blend-mode: multiply;
    opacity: 0.6;
    border-radius: 50%;
    background: transparent;
    width: 400px;
    height: 400px;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

@media (max-width: 768px) {
  .wordchange-dark::before {
    font-family: "montserrat", serif;
    font-size: 1.3rem;
    margin: 20px;
  }
  .home_p-dark {
    font-size: 0.8rem;
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (min-width: 500px) {
  .nebula-dark {
    width: 400px;
    height: 400px;
    top: 2%;
    left: 10%;
  }
}
@media (min-width: 1000px) {
  .nebula-dark {
    width: 700px;
    height: 700px;
    top: 2%;
    left: 10%;
  }
}

/* .underline1-dark {
  width: 27rem;
  height: 0.01rem;
  background-color: white;
  animation: slide-in ease-in 3s;
}

.underline2-dark {
  height: 0.01rem;
  background-color: white;
  width: 25rem;
  animation: slide-in-right ease-in 3s;
}

.arrow_style-dark {
  color: white;
} */

/* ABOUT---------------------------------------------- */
/* ABOUT---------------------------------------------- */
/* ABOUT---------------------------------------------- */

.about-container-dark {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(42 42 50);
  flex-direction: column;
  margin-top: -150px;
}
.about-description-dark {
  width: 90%;
  padding: 20px;
  margin-top: 200px;
  margin-bottom: 50px;
  overflow: hidden;
}

@media (max-width: 650px) {
  .about-description-dark {
    width: 90%;
    padding: 20px;
    margin-top: -10px;
    margin-bottom: 50px;
    overflow: hidden;
    line-height: 1.5;
  }
}

.about-description-dark p {
  font-family: "montserrat";
  font-size: 1.5rem;
  margin-bottom: 1rem;
  margin: 20px;
  color: white;
}

.about-description-dark h3 {
  font-size: 1.9rem;
  margin: 20px;
  letter-spacing: 0.15em;
  color: white;
}

@media (max-width: 500px) {
  .about-description-dark p {
    font-size: 1.2rem;
  }
  .about-description-dark h3 {
    font-size: 1.5rem;
  }
}

.services-item-dark h3 {
  font-family: "DM Serif Display", serif;
  font-size: 1.9rem;
  color: white;
}
.services-item-dark p {
  font-family: "montserrat", serif;
  line-height: 1.5;
  font-weight: 200;
  font-size: 1.3rem;
  color: white;
}

/* PROJECTS -------------*/

.projects-container-dark {
  padding-top: 3rem;
  background-color: rgb(42 42 50);
}

@keyframes blink {
  0%,
  2%,
  35%,
  39%,
  41%,
  100% {
    opacity: 1;
  }
  1%,
  3%,
  34%,
  36%,
  38%,
  40% {
    opacity: 0;
  }
}
